import { ethers } from "ethers";

import Mint from "./Mint";
import { MintSectionProps } from "../../types/MintSectionType";

export type NotCompleteConditionsResponse = {
  code: string | "MIN_ETH_BALANCE_NOT_REACHED";
  isFollowing?: boolean;
  isLikeTweet?: boolean;
  isRetweet?: boolean;
  msg: string;
  status: number;
};

function MintSection({
  mintActive,
  mintPrice,
  maxSupply,
  maxTokenPerPurchase,
  maxMintAmount,
  userMintInfo,
  freeClaimableTokenAmount,
  referralWalletAddress = ethers.constants.AddressZero,
  mintAmount = 10,
  errorCode,
  setMintAmount,
  decreaseMintAmount,
  increaseMintAmount,
  onMint,
  totalSupply = 0,
  setReferralWalletAddress,
  proof,
  hoppiProof,
}: MintSectionProps) {
  return (
    <>
      <Mint
        mintActive={mintActive}
        mintPrice={mintPrice}
        maxSupply={maxSupply}
        maxTokenPerPurchase={maxTokenPerPurchase}
        maxMintAmount={maxMintAmount}
        userMintInfo={userMintInfo}
        freeClaimableTokenAmount={freeClaimableTokenAmount}
        referralWalletAddress={referralWalletAddress}
        mintAmount={mintAmount}
        errorCode={errorCode}
        setMintAmount={setMintAmount}
        decreaseMintAmount={decreaseMintAmount}
        increaseMintAmount={increaseMintAmount}
        onMint={onMint}
        totalSupply={totalSupply}
        setReferralWalletAddress={setReferralWalletAddress}
        proof={proof}
        hoppiProof={hoppiProof}
      />
    </>
  );
}

export default MintSection;
