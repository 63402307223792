import Button from "../..//components/shared/Button";
import { twMerge } from "tailwind-merge";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";

const TwitterError = () => {
  return (
    <>
      <Layout className={"bg-transparent"}>
        <div
          className={twMerge(
            "flex w-screen h-[calc(100vh-104px)] justify-center overflow-x-hidden bg-black text-white",
          )}
        >
          <div className="flex flex-col w-fit h-[calc(100vh-150px)] justify-center items-center">
            <div className="text-center max-w-screen-md px-7 py-7 sm:px-1 sm:p-10">
              <div className="text-center max-w-screen-md px-5 py-5 sm:px-10 sm:py-10 drop-shadow-xl">
                <h1 className="font-goodgirl sm:block text-8xl sm:text-9xl mb-5">
                  Bummer!
                </h1>
                <h1 className="text-lg sm:text-3xl font-semibold">
                  This Twitter account is already connected to another wallet.
                  No worries though! Just pick another Twitter account and
                  you'll be all set to enjoy those cool vibes! 🌈✌️
                </h1>
                <Link to="/">
                  <Button className="bg-white text-black font-semibold py-2 px-10 text-2xl hover:scale-105 active:scale-[95%] transform transition duration-300 ease-in-out mt-7">
                    Retry
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TwitterError;
