import { useSwitchNetwork } from "wagmi";
import { goerli, mainnet } from "wagmi/chains";
import Button from "../shared/Button";

const chainId =
  process.env.REACT_APP_ENABLE_TESTNETS === "true" ? goerli.id : mainnet.id;

export default function WrongChainWarning() {
  const { switchNetwork } = useSwitchNetwork({
    chainId,
  });

  return (
    <div className="flex flex-col w-screen h-[calc(100vh-140px)] justify-center items-center">
      <div className="text-center max-w-screen-md px-7 py-7 sm:px-1 sm:p-10">
        <div className="text-center max-w-screen-md px-5 py-5 sm:px-10 sm:py-10 text-white drop-shadow-xl">
          <h1 className="text-lg sm:text-3xl font-semibold">
            You&apos;re on a different network.
          </h1>
          <h4 className="text-md sm:text-xl mt-3">
            To continue, switch to Ethereum mainnet.
          </h4>
          <Button
            onClick={() => {
              if (switchNetwork) {
                switchNetwork(chainId);
              }
            }}
            className="bg-white text-black font-semibold py-2 px-4 mt-3 text-lg hover:scale-105 active:scale-[95%] transform transition duration-300 ease-in-out"
          >
            Switch to Mainnet
          </Button>
        </div>
      </div>
    </div>
  );
}
