import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  useAccount,
  useContractReads,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  ConnectorData,
} from "wagmi";
import { ethers } from "ethers";

import Layout from "../components/Layout";
import nftABI from "../utils/contracts/abis/nft.json";
import LoadingView from "../components/shared/LoadingView";
import MintLoading from "../components/MintLoading";

import Button from "../components/shared/Button";
import ClaimTxPending from "../components/RewardsSection/ClaimTxPending";
import ClaimTxSuccess from "../components/RewardsSection/ClaimTxSuccess";
import toast from "react-hot-toast";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const nftContractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS || "0x0";

function Referral() {
  const { address, connector: activeConnector } = useAccount();
  const websiteLink = `https://chaossymphony.3xp.art/mint/${address}`;
  const [opinionText] = useState(
    `You gotta check out Chaos Symphony below... nice generative art and super cheap too`,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isPayoutActive, setIsPayoutActive] = useState<any>(false);
  const [userMintInfo, setUserMintInfo] = useState<any>({});

  const { openConnectModal } = useConnectModal();

  useContractReads({
    contracts: [
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "isPayoutActive",
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "userMintInfo",
        args: [address],
      },
    ],
    onSuccess(data) {
      const [isPayoutActive, userMintInfo] = data;
      setIsPayoutActive(isPayoutActive);
      setUserMintInfo(userMintInfo);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
      window.location.reload();
    };
    if (activeConnector) {
      activeConnector.on("change", handleConnectorUpdate);
    }
  }, [activeConnector]);

  const { config: publicConfig } = usePrepareContractWrite({
    address: nftContractAddress as `0x${string}`,
    abi: nftABI,
    functionName: "claimPayout",
    enabled: !openConnectModal,
  });
  const {
    data: claimPayoutData,
    isLoading: isClaiming,
    writeAsync: claimPayoutAsync,
  } = useContractWrite(publicConfig);

  const {
    isLoading: claimPayoutDataTxPending,
    isSuccess: claimPayoutDataTxSuccess,
  } = useWaitForTransaction({
    hash: claimPayoutData?.hash,
  });

  const onClaim = async (e: any) => {
    e.stopPropagation();
    if (isPayoutActive) {
      try {
        await claimPayoutAsync?.();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const copyReferralLink = (e: any) => {
    e.stopPropagation();
    navigator.clipboard.writeText(websiteLink);
    toast.success("Copied to clipboard!");
  };

  return (
    <Layout>
      <>
        {isLoading && <LoadingView />}
        {isClaiming && <MintLoading />}
        {claimPayoutDataTxPending && claimPayoutData && (
          <ClaimTxPending mintData={claimPayoutData} />
        )}
        {claimPayoutDataTxSuccess && <ClaimTxSuccess />}

        {!isLoading && (
          <div className={twMerge("flex justify-center items-center")}>
            <div className="flex flex-col justify-center items-center w-full py-14 md:py-14 text-white bg-black">
              <div className="flex flex-col justify-center items-center text-center w-[80%] sm:w-[90%] xl:max-w-5xl xl:w-full">
                <h2 className="text-5xl mb-10">Referrals</h2>
                {openConnectModal ? (
                  <>
                    <h3 className="text-xl font-medium">
                      Get <span className="underline">30%</span> commission when
                      your referral mints Chaos Symphony 🤑
                    </h3>

                    <Button
                      className="bg-white font-bold text-black py-4 px-10 text-2xl w-fit mt-8 mx-auto"
                      onClick={openConnectModal}
                    >
                      Connect Wallet
                    </Button>
                  </>
                ) : (
                  <>
                    {userMintInfo && (
                      <>
                        <div className="flex flex-col gap-2">
                          <h3 className="text-lg font-medium">Earnings</h3>
                          <div>
                            <h3 className="text-3xl font-medium">
                              {ethers.utils.formatEther(
                                userMintInfo?.revenueShareAmount,
                              )}{" "}
                              ETH
                            </h3>
                            <h3 className="text-md font-medium mt-1">
                              Lifetime Earnings:{" "}
                              {ethers.utils.formatEther(
                                userMintInfo?.revenueShareAmount.add(
                                  userMintInfo?.claimedRevenueShareAmount,
                                ),
                              )}{" "}
                              ETH
                            </h3>
                          </div>
                          <Button
                            className="bg-[#55c3b7] text-black py-4 min-w-[50%] mx-auto font-medium mt-4 disabled:text-gray-500"
                            onClick={onClaim}
                            disabled={
                              !isPayoutActive ||
                              ethers.utils.formatEther(
                                userMintInfo?.revenueShareAmount,
                              ) === "0.0"
                            }
                          >
                            {isPayoutActive ? "Claim" : "Payout is not active"}
                          </Button>
                        </div>

                        <h3 className="text-xl font-medium mt-20">
                          Get <span className="underline">30%</span> commission
                          when your referral mints Chaos Symphony 🤑
                        </h3>
                        <div className="flex gap-0 items-center justify-center mt-6">
                          <div
                            className="bg-white text-black border border-white p-2 text-xl rounded-l-lg font-medium w-[50%] overflow-hidden cursor-pointer"
                            onClick={copyReferralLink}
                          >
                            {websiteLink}
                          </div>
                          <div
                            className="bg-black text-white border border-white p-2 text-xl font-medium cursor-pointer active:scale-[98%] hover:scale-[105%] transform transition duration-300 ease-in-out rounded-r-lg"
                            onClick={copyReferralLink}
                          >
                            Copy
                          </div>
                        </div>

                        <Button
                          onClick={() => {
                            window.open(
                              `https://twitter.com/share?url=${websiteLink}&text=${encodeURIComponent(
                                `${opinionText} #nft`,
                              )}&via=3XPart`,
                              "_blank",
                            );

                            window.location.reload();
                          }}
                          className="bg-white text-black p-3 text-xl font-medium cursor-pointer active:scale-[98%] hover:scale-[105%] transform transition duration-300 ease-in-out mt-5"
                        >
                          Tweet This!
                        </Button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </Layout>
  );
}

export default Referral;
