import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import Button from "./Button";

interface DropdownMenuProps {
  items: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
  }[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const DropdownMenu = ({ items, visible, setVisible }: DropdownMenuProps) => {
  const parentRef = useRef<HTMLUListElement>(null);

  /** @todo apply this method to all click outside handlers later */
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        parentRef.current &&
        !parentRef.current.contains(e.target as Node) &&
        !parentRef.current.parentNode?.contains(e.target as Node)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setVisible]);

  if (!visible) return null;

  return (
    <ul
      ref={parentRef}
      className={twMerge(
        "absolute top-20 mt-1 right-5 z-50 min-w-[10rem]",
        "p-2 px-2.5",
        "bg-white text-black rounded-lg",
        "shadow-lg shadow-black-500 drop-shadow-md ",
      )}
    >
      {items.map((item, index) => {
        const { label, onClick, disabled, className } = item;

        return (
          <li key={label + index}>
            <Button
              className={twMerge(
                "w-full py-1 px-2.5 text-left disabled:text-gray-500",
                className,
              )}
              onClick={onClick}
              disabled={disabled}
            >
              {label}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};

export default DropdownMenu;
