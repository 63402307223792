import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  useAccount,
  useContractReads,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  ConnectorData,
} from "wagmi";
import { ethers } from "ethers";

import { MintErrorCodeType } from "../types/MintErrorCodeType";
import Layout from "../components/Layout";
import nftABI from "../utils/contracts/abis/nft.json";
import LoadingView from "../components/shared/LoadingView";
import MintLoading from "../components/MintLoading";
import TxPending from "../components/TxPending";
import TxSuccess from "../components/TxSuccess";
import FreeMintSection from "../components/FreeMintSection";
import { getFreeMintAmount, getHoppiProof } from "../utils/merkle";

const nftContractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS || "0x0";

function FreeClaim() {
  const { address, connector: activeConnector } = useAccount();
  const [isLoading, setIsLoading] = useState(true);
  const [errorCode, setErrorCode] = useState<MintErrorCodeType>("NOT_STARTED");
  const [mintAmount, setMintAmount] = useState(5);

  const [mintActive, setMintActive] = useState(false);
  const [userMintInfo, setUserMintInfo] = useState<any>({});

  const [maxSupply, setMaxSupply] = useState<any>(0);
  const [totalSupply, setTotalSupply] = useState<any>(0);

  const [hoppiProof, setHoppiProof] = useState([
    ethers.utils.formatBytes32String("0"),
  ]);

  useContractReads({
    contracts: [
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "mintActive",
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "MAX_TOTAL_SUPPLY",
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "totalSupply",
      },
    ],
    onSuccess(data) {
      const [mintActive, maxSupply, totalSupply] = data;

      setMintActive(mintActive as boolean);
      setMaxSupply(maxSupply);
      setTotalSupply(totalSupply);
      setIsLoading(false);
    },
  });

  useContractReads({
    contracts: [
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "userMintInfo",
        args: [address],
      },
    ],
    enabled: !!address && !!maxSupply,
    onSuccess(data) {
      const [userMintInfo] = data;

      setUserMintInfo(userMintInfo);

      let _hoppiProof = hoppiProof;

      if (address) {
        _hoppiProof = getHoppiProof(address);
        setHoppiProof(_hoppiProof);
        console.log("_hoppiProof->", _hoppiProof);

        const mintAmount = getFreeMintAmount(address);
        setMintAmount(mintAmount);
      }
    },
  });

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
      window.location.reload();
    };
    if (activeConnector) {
      activeConnector.on("change", handleConnectorUpdate);
    }
  }, [activeConnector]);

  useEffect(() => {
    if (totalSupply) {
      if (!mintActive && totalSupply?.toNumber() === 0) {
        return setErrorCode("NOT_STARTED");
      }
      if (!mintActive && totalSupply?.toNumber() > 0) {
        return setErrorCode("MINT_CLOSED");
      }
    }
    if (mintActive) {
      return setErrorCode("MINT_READY");
    }
  }, [mintActive, totalSupply]);

  const isLoadingData = isLoading;

  // free mint
  const { config: publicConfig } = usePrepareContractWrite({
    address: nftContractAddress as `0x${string}`,
    abi: nftABI,
    functionName: "freeMint",
    args: [hoppiProof, mintAmount],
    overrides: {
      from: address,
      // value: ethers.utils
      //   .parseUnits(mintPrice?.toString() || "0", 0)
      //   .mul(
      //     ethers.utils.parseUnits(
      //       (mintAmount - freeClaimableTokenAmount?.toNumber()).toString(),
      //       0,
      //     ),
      //   ),
    },
  });
  const {
    data: freeMintData,
    isLoading: isFreeMinting,
    writeAsync: freeMintAsync,
  } = useContractWrite(publicConfig);

  const {
    data: freeMintTxData,
    isLoading: freeMintTxPending,
    isSuccess: freeMintTxSuccess,
  } = useWaitForTransaction({
    hash: freeMintData?.hash,
  });

  const onMint = async (e: any) => {
    e.stopPropagation();
    if (mintActive) {
      try {
        await freeMintAsync?.();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <Layout>
      <>
        {isLoadingData && <LoadingView />}
        {!isLoadingData && (
          <div className={twMerge("flex justify-center items-center")}>
            <div className="text-center">
              {!isLoadingData && (
                <div className={twMerge("flex justify-center items-center")}>
                  {isFreeMinting && <MintLoading />}
                  {freeMintTxPending && freeMintData && (
                    <TxPending mintData={freeMintData} />
                  )}
                  {freeMintTxSuccess && <TxSuccess />}

                  <div className="text-center">
                    {!isLoadingData && (
                      <FreeMintSection
                        mintActive={mintActive}
                        hoppiProof={hoppiProof}
                        maxSupply={maxSupply}
                        userMintInfo={userMintInfo}
                        mintAmount={mintAmount}
                        errorCode={errorCode}
                        onMint={onMint}
                        totalSupply={totalSupply}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </Layout>
  );
}

export default FreeClaim;
