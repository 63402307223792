import Button from "../shared/Button";
import { Link } from "react-router-dom";

export default function TakingOverTxSuccess() {
  return (
    <>
      <div
        className={`fixed h-screen top-0 left-0 z-10 transition-opacity duration-300 ease-in-out`}
      >
        <div className="fixed w-screen h-screen top-0 left-0 z-10 text-center bg-black p-10" />
        <div className="fixed top-0 left-0 z-50 text-center animate-slide-top bg-transparent flex flex-col w-full h-full justify-center items-center">
          <div className="flex flex-col justify-center items-center p-10">
            <div className="bg-black text-white p-10">
              <h1 className="mt-10 text-7xl sm:text-[7rem] xl:text-[8rem] 2xl:text-[10rem] mb-2 2xl:mb-5 text-center">
                Well Done!
              </h1>
              <div className="max-w-screen-md mx-auto">
                <p className="text-xl">
                  {`Good job getting those referrals. Enjoy!`}
                </p>
                <Link to="/">
                  <Button className="bg-white font-bold text-black py-2 px-10 text-2xl w-fit mt-8 mx-auto">
                    Back
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
