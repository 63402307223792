import { truncateAccount } from "../utils";
import { twMerge } from "tailwind-merge";

export default function TxPending({
  mintData,
}: {
  mintData?: { hash: string };
}) {
  return (
    <>
      <div
        className={twMerge(
          "fixed top-0 left-0 w-screen h-screen overflow-hidden flex justify-center items-center bg-black text-white z-[999]",
        )}
      >
        <div className="flex flex-col justify-center items-center w-full p-3 z-20 animate-pulse">
          <h4 className={twMerge("text-4xl mb-3")}>
            Making the magic happen 🎨
          </h4>
          <h4 className={twMerge("text-xl mb-3")}>Do not close your browser</h4>
          <a
            href={`https://etherscan.io/tx/${mintData?.hash}`}
            target="_blank"
            rel="noreferrer"
            className={twMerge("underline text-lg")}
          >
            {truncateAccount(mintData?.hash)}
          </a>
        </div>
      </div>
    </>
  );
}
