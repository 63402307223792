import { twMerge } from "tailwind-merge";
import { ethers } from "ethers";

import Button from "../shared/Button";
import { MintSectionProps } from "../../types/MintSectionType";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import Icon from "../shared/Icon";

const minMintAmount = 1;

function MintSection({
  mintActive,
  mintPrice,
  maxSupply,
  maxMintAmount,
  maxTokenPerPurchase,
  userMintInfo,
  freeClaimableTokenAmount,
  referralWalletAddress = ethers.constants.AddressZero,
  mintAmount = 10,
  errorCode,
  setMintAmount,
  decreaseMintAmount,
  increaseMintAmount,
  onMint,
  totalSupply = 0,
  setReferralWalletAddress,
  proof,
  hoppiProof,
}: MintSectionProps) {
  const { openConnectModal } = useConnectModal();

  return (
    <div className="flex flex-col w-full justify-center mt-3">
      <div className="flex flex-col xl:flex-row gap-14 text-black px-5 py-20 pt-28 lg:px-20 sm:py-24 sm:pt-32 items-center relative w-[90%] xl:w-[100%] mx-auto rounded-lg z-[99]">
        {openConnectModal ? (
          <div className="flex flex-col text-white">
            <h1 className="text-5xl sm:text-6xl md:text-9xl font-bold text-center">
              Connect
            </h1>
            <Button
              className="bg-white font-bold text-black py-4 px-10 text-2xl w-fit mt-8 mx-auto"
              onClick={openConnectModal}
            >
              Connect Wallet
            </Button>
          </div>
        ) : (
          <>
            {!mintActive && totalSupply < maxSupply && (
              <div className="flex flex-col text-white justify-center items-center">
                <h1 className="text-5xl sm:text-6xl md:text-9xl font-bold text-center">
                  Mint not active
                </h1>
              </div>
            )}
            {!mintActive && totalSupply >= maxSupply && (
              <div className="flex flex-col text-white">
                <h1 className="text-5xl sm:text-6xl md:text-9xl font-bold text-center">
                  Mint is over!
                </h1>
                <a
                  href="https://opensea.io/collection/chaossymphony"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="bg-white font-bold text-black py-4 px-10 text-2xl w-fit mt-4 mx-auto">
                    OpenSea Baby!
                  </Button>
                </a>
              </div>
            )}

            {mintActive && (
              <>
                <div className="max-w-screen-sm">
                  <div className="text-white">
                    <img
                      src="/images/VISUAL 1.gif"
                      alt="chaos-symphony-nft"
                      className="w-52 -mt-44 -mb-10 mx-auto"
                    />
                    <h1 className="text-[4rem] font-bold text-center leading-[0.9]">
                      Chaos
                      <br />
                      Symphony
                    </h1>
                    <div className="flex bg-white w-full items-center justify-center rounded-xl p-1 mx-auto mt-12 gap-4">
                      <div className="text-black w-fit mx-auto p-2.5 rounded-xl">
                        <h2 className="text-md">
                          Price:{" "}
                          <Icon
                            name="icon-whale-eth"
                            className="text-xl relative top-0.5"
                          />
                          {ethers.utils.formatEther(mintPrice)}
                        </h2>
                      </div>
                    </div>
                    {((hoppiProof[0] !==
                      ethers.utils.formatBytes32String("0") &&
                      hoppiProof.length > 0) ||
                      (proof[0] !== ethers.utils.formatBytes32String("0") &&
                        proof.length > 0) ||
                      (userMintInfo as any)?.freeClaimedAmount?.toNumber() >
                        0) &&
                      (userMintInfo as any)?.wlMintAmount <
                        maxTokenPerPurchase && (
                        <h3 className="mt-3 text-green-400">
                          You are eligible for the discounted price
                        </h3>
                      )}
                    <div className="flex flex-col gap-4 justify-center">
                      <div className="mt-8">
                        <img
                          src="/images/VISUAL 1.gif"
                          alt="chaos-symphony-nft"
                          className="w-20 mx-auto border-2 border-white rounded-xl"
                        />
                        <Button
                          className={twMerge(
                            "text-3xl select-none",
                            "hover:scale-105 transform transition duration-300 ease-in-out",
                            "disabled:opacity-50 disabled:bg-transparent",
                            "font-black",
                          )}
                          onClick={decreaseMintAmount}
                          disabled={
                            minMintAmount === mintAmount ||
                            mintAmount === freeClaimableTokenAmount.toNumber()
                          }
                        >
                          -
                        </Button>
                        <h4 className="text-3xl select-none tracking-wide font-bold inline">
                          {mintAmount}
                        </h4>
                        <Button
                          className={twMerge(
                            "text-3xl select-none",
                            "hover:scale-105 transform transition duration-300 ease-in-out",
                            "disabled:opacity-50 disabled:bg-transparent",
                            "font-black",
                          )}
                          onClick={increaseMintAmount}
                          disabled={maxMintAmount === mintAmount}
                        >
                          +
                        </Button>
                      </div>
                      <Button
                        className={`text-md text-black w-fit bg-white mx-auto rounded-full -mt-5 py-0 disabled:text-gray-500`}
                        onClick={() => {
                          setMintAmount(maxMintAmount);
                        }}
                        disabled={maxMintAmount === mintAmount}
                      >
                        MAX
                      </Button>
                    </div>

                    <input
                      type="text"
                      value={
                        referralWalletAddress !== ethers.constants.AddressZero
                          ? referralWalletAddress
                          : ""
                      }
                      className="border- border-white bg-black text-white text-lg mt-8 px-4 w-full focus:border-white rounded-lg"
                      placeholder="Referral Code (Optional)"
                      onChange={(e) => setReferralWalletAddress(e.target.value)}
                      max={8}
                    />
                    <div>
                      <Button
                        className="bg-white text-black font-bold py-2 px-14 text-xl w-full mt-6"
                        onClick={onMint}
                      >
                        Mint {mintAmount} for
                        {mintAmount === freeClaimableTokenAmount.toNumber() ? (
                          <> FREE~</>
                        ) : (
                          <>
                            <Icon name="icon-whale-eth" className="text-xl" />
                            {ethers.utils.formatEther(
                              mintPrice.mul(
                                mintAmount -
                                  freeClaimableTokenAmount.toNumber(),
                              ),
                            )}
                          </>
                        )}
                      </Button>
                    </div>
                    <h5 className="mt-3 text-sm">
                      {totalSupply.toLocaleString()}/
                      {maxSupply.toLocaleString()} Minted
                    </h5>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="overflow-hidden">
        <img
          src="/images/VISUAL 3388.gif"
          alt="chaos-symphony-nft"
          className="w-[60%] fixed hidden xl:block xl:-bottom-[15%] 2xl:-bottom-[30%] -left-[15%] opacity-[15%] overflow-hidden"
        />
        <img
          src="/images/VISUAL 3499.gif"
          alt="chaos-symphony-nft"
          className="w-[75%] fixed hidden xl:block -bottom-[0%] -right-[30%] opacity-[15%] overflow-hidden"
        />
      </div>
    </div>
  );
}

export default MintSection;
