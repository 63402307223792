import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import {
  useAccount,
  useContractReads,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  ConnectorData,
} from "wagmi";
import { BigNumber, ethers } from "ethers";

import { MintErrorCodeType } from "../types/MintErrorCodeType";
import Layout from "../components/Layout";
import nftABI from "../utils/contracts/abis/nft.json";
import LoadingView from "../components/shared/LoadingView";
import MintLoading from "../components/MintLoading";
import TxPending from "../components/TxPending";
import TxSuccess from "../components/TxSuccess";
import MintSection from "../components/MintSection";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { getProof, getHoppiProof } from "../utils/merkle";

const nftContractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS || "0x0";

function Sale() {
  let { referralAddress } = useParams();
  const { address, connector: activeConnector } = useAccount();
  const [isLoading, setIsLoading] = useState(true);
  const [errorCode, setErrorCode] = useState<MintErrorCodeType>("NOT_STARTED");
  const [mintAmount, setMintAmount] = useState(5);
  const [maxMintAmount, setMaxMintAmount] = useState<any>(5);
  const [mintActive, setMintActive] = useState(false);
  const [userMintInfo, setUserMintInfo] = useState<any>({});
  const [freeClaimableTokenAmount, setFreeClaimableTokenAmount] = useState<any>(
    BigNumber.from(0),
  );
  const [mintPrice, setMintPrice] = useState<any>(BigNumber.from(0));
  const [maxSupply, setMaxSupply] = useState<any>(0);
  const [maxTokenPerPurchase, setMaxTokenPerPurchase] = useState<any>(0);
  const [totalSupply, setTotalSupply] = useState<any>(0);
  const [referralWalletAddress, setReferralWalletAddress] = useState(
    ethers.constants.AddressZero,
  );

  const [proof, setProof] = useState([ethers.utils.formatBytes32String("0")]);
  const [hoppiProof, setHoppiProof] = useState([
    ethers.utils.formatBytes32String("0"),
  ]);

  const minMintAmount = 0;

  const { openConnectModal } = useConnectModal();

  useContractReads({
    contracts: [
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "mintActive",
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "MAX_TOTAL_SUPPLY",
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "MAX_TOKENS_PER_PURCHASE",
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "totalSupply",
      },
    ],
    onSuccess(data) {
      const [mintActive, maxSupply, maxTokenPerPurchase, totalSupply] = data;

      setMintActive(mintActive as boolean);
      setMaxSupply(maxSupply);
      setMaxTokenPerPurchase(maxTokenPerPurchase);
      setTotalSupply(totalSupply);
      setIsLoading(false);
    },
  });

  useContractReads({
    contracts: [
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "userMintInfo",
        args: [address],
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "MINT_PRICE",
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: nftABI,
        functionName: "PUBLIC_MINT_PRICE",
      },
    ],
    enabled: !!address && !!maxTokenPerPurchase,
    onSuccess(data) {
      const [userMintInfo, mintPrice, publicMintPrice] = data;

      setUserMintInfo(userMintInfo);

      let _proof = proof;
      let _hoppiProof = hoppiProof;

      if (address) {
        _proof = getProof(address);
        setProof(_proof);

        _hoppiProof = getHoppiProof(address);
        setHoppiProof(_hoppiProof);
      }

      if (
        ((_proof[0] !== ethers.utils.formatBytes32String("0") &&
          _proof.length > 0) ||
          (_hoppiProof[0] !== ethers.utils.formatBytes32String("0") &&
            _hoppiProof.length > 0)) &&
        (userMintInfo as any)?.wlMintAmount < (maxTokenPerPurchase as BigNumber)
      ) {
        setMintPrice(mintPrice);
      } else {
        setMintPrice(publicMintPrice);
      }

      setMaxMintAmount(
        (freeClaimableTokenAmount as BigNumber)
          .add(maxTokenPerPurchase as BigNumber)
          .toNumber() as number,
      );

      if ((freeClaimableTokenAmount as BigNumber).toNumber() > 0) {
        setMintAmount(
          parseInt(
            ethers.utils.formatUnits(freeClaimableTokenAmount as BigNumber, 0),
            10,
          ) as number,
        );
      } else if (
        (userMintInfo as any)?.wlMintAmount < (maxTokenPerPurchase as BigNumber)
      ) {
        setMintAmount(
          parseInt(
            ethers.utils.formatUnits(
              (maxTokenPerPurchase as BigNumber).sub(
                (userMintInfo as any)?.wlMintAmount,
              ),
              0,
            ),
            10,
          ) as number,
        );

        setMaxMintAmount(
          (maxTokenPerPurchase as BigNumber)
            .sub((userMintInfo as any)?.wlMintAmount)
            .toNumber() as number,
        );
      } else {
        setMintAmount(
          parseInt(
            ethers.utils.formatUnits(maxTokenPerPurchase as BigNumber, 0),
            10,
          ) as number,
        );
      }
    },
  });

  useEffect(() => {
    if (
      referralAddress &&
      ethers.utils.getAddress(referralAddress) !== address &&
      !openConnectModal
    ) {
      try {
        setReferralWalletAddress(ethers.utils.getAddress(referralAddress));
      } catch (error) {
        // throw new HttpException('Invalid wallet address', HttpStatus.BAD_REQUEST);
      }
    }
  }, [referralAddress, address, openConnectModal]);

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
      window.location.reload();
    };
    if (activeConnector) {
      activeConnector.on("change", handleConnectorUpdate);
    }
  }, [activeConnector]);

  useEffect(() => {
    if (totalSupply) {
      if (!mintActive && totalSupply?.toNumber() === 0) {
        return setErrorCode("NOT_STARTED");
      }
      if (!mintActive && totalSupply?.toNumber() > 0) {
        return setErrorCode("MINT_CLOSED");
      }
    }
    if (mintActive) {
      return setErrorCode("MINT_READY");
    }
  }, [mintActive, totalSupply]);

  const isLoadingData = isLoading;

  // public mint
  const { config: publicConfig } = usePrepareContractWrite({
    address: nftContractAddress as `0x${string}`,
    abi: nftABI,
    functionName: "mint",
    args: [mintAmount, referralWalletAddress, proof],
    overrides: {
      from: address,
      value: ethers.utils
        .parseUnits(mintPrice?.toString() || "0", 0)
        .mul(ethers.utils.parseUnits(mintAmount.toString(), 0)),
    },
  });

  const {
    data: publicMintData,
    isLoading: isPublicMinting,
    writeAsync: publicMintAsync,
  } = useContractWrite(publicConfig);

  const {
    data: publicMintTxData,
    isLoading: publicMintTxPending,
    isSuccess: publicMintTxSuccess,
  } = useWaitForTransaction({
    hash: publicMintData?.hash,
  });

  const onMint = async (e: any) => {
    e.stopPropagation();
    if (mintActive) {
      try {
        await publicMintAsync?.();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const decreaseMintAmount = () => {
    if (mintAmount > minMintAmount && mintAmount > freeClaimableTokenAmount) {
      setMintAmount(mintAmount - 1);
    }
  };
  const increaseMintAmount = () => {
    if (mintAmount < maxMintAmount) {
      setMintAmount(mintAmount + 1);
    }
  };

  return (
    <Layout>
      <>
        {isLoadingData && <LoadingView />}
        {!isLoadingData && (
          <div className={twMerge("flex justify-center items-center")}>
            <div className="text-center">
              {!isLoadingData && (
                <div className={twMerge("flex justify-center items-center")}>
                  {isPublicMinting && <MintLoading />}
                  {publicMintTxPending && publicMintData && (
                    <TxPending mintData={publicMintData} />
                  )}
                  {publicMintTxSuccess && <TxSuccess />}

                  <div className="text-center">
                    {!isLoadingData && (
                      <MintSection
                        mintActive={mintActive}
                        mintPrice={mintPrice}
                        proof={proof}
                        hoppiProof={hoppiProof}
                        maxSupply={maxSupply}
                        maxTokenPerPurchase={maxTokenPerPurchase}
                        maxMintAmount={maxMintAmount}
                        userMintInfo={userMintInfo}
                        freeClaimableTokenAmount={freeClaimableTokenAmount}
                        referralWalletAddress={referralWalletAddress}
                        mintAmount={mintAmount}
                        errorCode={errorCode}
                        setMintAmount={setMintAmount}
                        decreaseMintAmount={decreaseMintAmount}
                        increaseMintAmount={increaseMintAmount}
                        onMint={onMint}
                        totalSupply={totalSupply}
                        setReferralWalletAddress={setReferralWalletAddress}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </Layout>
  );
}

export default Sale;
