import { truncateAccount } from "../../utils";
import { twMerge } from "tailwind-merge";

export default function TakingOverTxPending({
  mintData,
}: {
  mintData?: { hash: string };
}) {
  return (
    <>
      <div
        className={`fixed w-screen h-screen overflow-hidden top-0 left-0 z-10 transition-opacity duration-300 ease-in-out bg-black text-white flex justify-center items-center`}
      >
        <div className="text-center w-fit m-auto p-3 px-8 my-5 bg-black animate-pulse">
          <h1 className={twMerge("text-4xl mb-3")}>Claiming...</h1>
          <h4 className={twMerge("text-xl mb-3")}>Do not close your browser</h4>
          <a
            href={`https://explorer.zksync.io/tx/${mintData?.hash}`}
            target="_blank"
            rel="noreferrer"
            className={twMerge("underline text-sm sm:text-md text-white")}
          >
            {truncateAccount(mintData?.hash)}
          </a>
        </div>
      </div>
    </>
  );
}
