import { ButtonHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

// -- MAIN
const Button = ({
  className,
  ...buttonProps
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const buttonClassName = twMerge(
    // this can be fully overrided
    "py-2 px-4 pt-2.5",
    "rounded-lg hover:bg-neutral-500 hover:bg-opacity-50 active:scale-[95%]",
    // none to remove bg-image before applying color
    "disabled:active:scale-100 disabled:bg-none disabled:bg-neutral-300",
    "disabled:cursor-not-allowed",
    "hover:border hover:border-white hover:bg-black hover:text-white",
    className,
  );

  return <button type="button" {...buttonProps} className={buttonClassName} />;
};

export default Button;
