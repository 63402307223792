import { twMerge } from "tailwind-merge";
import { ethers } from "ethers";

import Button from "../shared/Button";
import { FreeMintSectionProps } from "../../types/MintSectionType";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import Icon from "../shared/Icon";

const minMintAmount = 1;

function FreeMint({
  mintActive,
  maxSupply,
  userMintInfo,
  mintAmount = 10,
  errorCode,
  onMint,
  totalSupply = 0,
  hoppiProof,
}: FreeMintSectionProps) {
  const { openConnectModal } = useConnectModal();

  return (
    <div className="flex flex-col w-full justify-center mt-3">
      <div className="flex flex-col xl:flex-row gap-14 text-black px-5 py-20 pt-28 lg:px-20 sm:py-24 sm:pt-32 items-center relative w-[90%] xl:w-[100%] mx-auto rounded-lg z-[99]">
        {openConnectModal ? (
          <div className="flex flex-col text-white">
            <h1 className="text-5xl sm:text-6xl md:text-9xl font-bold text-center">
              Connect
            </h1>
            <Button
              className="bg-white font-bold text-black py-4 px-10 text-2xl w-fit mt-8 mx-auto"
              onClick={openConnectModal}
            >
              Connect Wallet
            </Button>
          </div>
        ) : (
          <>
            {!mintActive && totalSupply < maxSupply && (
              <div className="flex flex-col text-white justify-center items-center">
                <h1 className="text-5xl sm:text-6xl md:text-9xl font-bold text-center">
                  Mint not active
                </h1>
              </div>
            )}
            {!mintActive && totalSupply >= maxSupply && (
              <div className="flex flex-col text-white">
                <h1 className="text-5xl sm:text-6xl md:text-9xl font-bold text-center">
                  Mint is over!
                </h1>
                <a
                  href="https://opensea.io/collection/chaossymphony"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="bg-white font-bold text-black py-4 px-10 text-2xl w-fit mt-4 mx-auto">
                    OpenSea Baby!
                  </Button>
                </a>
              </div>
            )}

            {mintActive && (
              <>
                <div className="max-w-screen-sm">
                  <div className="text-white">
                    <img
                      src="/images/VISUAL 1.gif"
                      alt="chaos-symphony-nft"
                      className="w-52 -mt-44 -mb-10 mx-auto"
                    />
                    <h1 className="text-[4rem] font-bold text-center leading-[0.9]">
                      Chaos
                      <br />
                      Symphony
                    </h1>
                    <div className="flex bg-white w-full items-center justify-center rounded-xl p-1 mx-auto mt-12 gap-4">
                      <div className=" bg-black text-white w-full mx-auto px-5 py-2.5 rounded-xl">
                        <h2 className="text-md">
                          Free claims :{" "}
                          {ethers.utils.formatUnits(mintAmount, 0)}
                        </h2>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 justify-center">
                      <div className="mt-8">
                        <img
                          src="/images/VISUAL 1.gif"
                          alt="chaos-symphony-nft"
                          className="w-20 mx-auto border-2 border-white rounded-xl"
                        />
                        <Button
                          className={twMerge(
                            "text-3xl select-none",
                            "hover:scale-105 transform transition duration-300 ease-in-out",
                            "disabled:opacity-50 disabled:bg-transparent",
                            "font-black",
                          )}
                          disabled
                        >
                          -
                        </Button>
                        <h4 className="text-3xl select-none tracking-wide font-bold inline">
                          {mintAmount}
                        </h4>
                        <Button
                          className={twMerge(
                            "text-3xl select-none",
                            "hover:scale-105 transform transition duration-300 ease-in-out",
                            "disabled:opacity-50 disabled:bg-transparent",
                            "font-black",
                          )}
                          disabled
                        >
                          +
                        </Button>
                      </div>
                    </div>

                    <div>
                      <Button
                        className="bg-white text-black font-bold py-2 px-14 text-xl w-full mt-6"
                        onClick={onMint}
                        disabled={mintAmount === 0 || userMintInfo?.freeClaimed}
                      >
                        {mintAmount === 0 ? (
                          "No free claim for you"
                        ) : (
                          <>
                            {userMintInfo?.freeClaimed
                              ? "Claimed"
                              : `Mint ${mintAmount} for FREE`}
                          </>
                        )}
                      </Button>
                    </div>
                    <h5 className="mt-3 text-sm">
                      {totalSupply.toLocaleString()}/
                      {maxSupply.toLocaleString()} Minted
                    </h5>

                    <h3 className="text-center text-sm text-white max-w-screen-sm mx-auto mt-5">
                      * {mintAmount} free mints because you hold {mintAmount}{" "}
                      Hoppi's.
                    </h3>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="overflow-hidden">
        <img
          src="/images/VISUAL 3388.gif"
          alt="chaos-symphony-nft"
          className="w-[60%] fixed hidden xl:block xl:-bottom-[15%] 2xl:-bottom-[30%] -left-[15%] opacity-[15%] overflow-hidden"
        />
        <img
          src="/images/VISUAL 3499.gif"
          alt="chaos-symphony-nft"
          className="w-[75%] fixed hidden xl:block -bottom-[0%] -right-[30%] opacity-[15%] overflow-hidden"
        />
      </div>
    </div>
  );
}

export default FreeMint;
