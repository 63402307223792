import { ethers } from "ethers";
import { MerkleTree } from "merkletreejs";

import wlAddresses from "./wl.json";
import hoppiOwners from "./hoppi.json";

//@ts-ignore
const uniqueAddresses = [...new Set(wlAddresses.map(data => data[0].trim()))];

const leafNodes = uniqueAddresses.map((rawAddress: string) => {
  const address = ethers.utils.getAddress(rawAddress);

  return ethers.utils.solidityKeccak256(["address"], [address]);
});

export const merkleTree = new MerkleTree(leafNodes, ethers.utils.keccak256, {
  sortPairs: true,
});

export const root = merkleTree.getHexRoot();

// console.log(root);

export const getProof = (address: `0x${string}`) => {
  const proof = merkleTree.getHexProof(
  ethers.utils.solidityKeccak256(["address"], [address]),
  );

  return proof;
}

const leafNodesHoppi = hoppiOwners.map(({ address, count } : { address: string, count: number }) => {
  const formattedAddress = ethers.utils.getAddress(address);

  return ethers.utils.solidityKeccak256(["address", "uint256"], [formattedAddress, count]);
});

export const merkleTreeHoppi = new MerkleTree(leafNodesHoppi, ethers.utils.keccak256, {
  sortPairs: true,
});

export const hoppiRoot = merkleTreeHoppi.getHexRoot();

// console.log('hoppiRoot->', hoppiRoot);

export const getHoppiProof = (address: `0x${string}`) => {
  const data = hoppiOwners.find((data) => {
    return ethers.utils.getAddress(data.address) === address;
  })


  if (data) {
    const proof = merkleTreeHoppi.getHexProof(
    ethers.utils.solidityKeccak256(["address", "uint256"], [address, data.count]),
    );
  
    return proof;
  } else {
    return [ethers.utils.formatBytes32String("0")];
  }
}

export const getFreeMintAmount = (address: `0x${string}`) => {
  const data = hoppiOwners.find((data) => {
    return ethers.utils.getAddress(data.address) === address;
  })

  let freeMintAmount = 0;
  if (data) {
    freeMintAmount = data.count;
  }

  return freeMintAmount;
}