import React, { useState } from "react";

import Button from "./shared/Button";
import { useAccount } from "wagmi";

export default function TxSuccess() {
  const { address } = useAccount();

  const websiteLink = `https://chaossymphony.3xp.art/mint/${address}`;
  const [opinionText] = useState(
    `You gotta check out Chaos Symphony below... nice generative art and super cheap too`,
  );

  return (
    <>
      <div
        className={`fixed h-screen top-0 left-0 transition-opacity duration-300 ease-in-out bg-black z-[999]`}
      >
        <div className="fixed w-screen h-screen top-0 left-0 text-center bg-black p-10" />
        <div className="fixed top-0 left-0 text-center animate-slide-top bg-transparent flex flex-col w-full h-full justify-center items-center">
          <div className="flex flex-col justify-center items-center p-10">
            <div className="text-white p-10 rounded-lg">
              <h1 className="mt-10 text-7xl sm:text-[7rem] xl:text-[8rem] 2xl:text-[10rem] mb-2 2xl:mb-5 text-center">
                MINTED!
              </h1>
              <div className="max-w-screen-xl mx-auto">
                <p className="text-2xl sm:mt-10">
                  Love Chaos Symphony? Then tweet your unique referral link to
                  earn a whopping 30% commission on each referral who mints! 🤑
                </p>

                <Button
                  className="bg-white font-bold text-black py-2 px-10 text-2xl w-fit mt-8 mx-auto"
                  onClick={() => {
                    window.open(
                      `https://twitter.com/share?url=${websiteLink}&text=${encodeURIComponent(
                        `${opinionText} #nft`,
                      )}&via=3XPart`,
                      "_blank",
                    );

                    window.location.reload();
                  }}
                >
                  Tweet This!
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
