import React from "react";
import Icon from "../shared/Icon";

export default function PoweredBy3XP() {
  return (
    <div className="fixed right-4 bottom-4 text-white">
      <h5 className="text-xs sm:text-sm mb-1">Powered by</h5>
      <>
        <a href="https://3xp.art/" target="_blank" rel="noreferrer">
          <img
            src="/3xp-logo/3xpart-logo-color-white.png"
            alt="3XP"
            className="h-5 sm:h-10 hover:scale-105 transform transition duration-300 ease-in-out"
          />
        </a>
        <div className="mt-1">
          <a href="https://twitter.com/3XPart" target="_blank" rel="noreferrer">
            <Icon
              name="icon-whale-twitter"
              className="text-xl hover:scale-110 transform transition duration-300 ease-in-out"
            />
          </a>
          {/* <a
            href="https://discord.gg/PyanAVsgJ2"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              name="icon-discord"
              className="text-xl ml-2 hover:scale-110 transform transition duration-300 ease-in-out"
            />
          </a> */}
          <a
            href="https://opensea.io/collection/chaossymphony"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              name="icon-opensea"
              className="text-xl ml-2 hover:scale-110 transform transition duration-300 ease-in-out"
            />
          </a>
        </div>
      </>
    </div>
  );
}
