import { twMerge } from "tailwind-merge";

export default function MintLoading() {
  return (
    <div
      className={twMerge(
        "fixed top-0 left-0 w-screen h-screen overflow-hidden flex justify-center items-center bg-black text-white z-[999]",
      )}
    >
      <div className="flex flex-col justify-center items-center w-full p-3 z-20 animate-pulse">
        <h1 className="p-10 sm:block text-4xl mb-5">
          Confirm your transaction
        </h1>
      </div>
    </div>
  );
}
