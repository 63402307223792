import FreeMint from "./FreeMint";
import { FreeMintSectionProps } from "../../types/MintSectionType";

export type NotCompleteConditionsResponse = {
  code: string | "MIN_ETH_BALANCE_NOT_REACHED";
  isFollowing?: boolean;
  isLikeTweet?: boolean;
  isRetweet?: boolean;
  msg: string;
  status: number;
};

function FreeMintSection({
  mintActive,
  maxSupply,
  userMintInfo,
  mintAmount = 10,
  errorCode,
  onMint,
  totalSupply = 0,
  hoppiProof,
}: FreeMintSectionProps) {
  return (
    <>
      <FreeMint
        mintActive={mintActive}
        maxSupply={maxSupply}
        userMintInfo={userMintInfo}
        mintAmount={mintAmount}
        errorCode={errorCode}
        onMint={onMint}
        totalSupply={totalSupply}
        hoppiProof={hoppiProof}
      />
    </>
  );
}

export default FreeMintSection;
