import { twMerge } from "tailwind-merge";

export default function ErrorPage({
  mintData,
}: {
  mintData?: { hash: string };
}) {
  return (
    <>
      <div
        className={twMerge(
          "fixed top-0 left-0 w-screen h-screen overflow-hidden flex justify-center items-center bg-black z-50 text-white",
        )}
      >
        <div className="flex flex-col justify-center items-center w-full p-3 z-20 text-center">
          <h4 className={twMerge("text-6xl mb-3")}>Not Found</h4>
          <h1 className="text-2xl font-semibold">The page is missing!</h1>
        </div>
      </div>
    </>
  );
}
